import Toast from "@/plugins/Toast";
import router from "@/router";
import _ from "lodash";
import { Md5 } from "ts-md5";
import Base64 from "crypto-js/enc-base64";
import hmacSHA256 from "crypto-js/hmac-sha256";
import { Buffer } from "buffer";
import { fileToBase64 } from "../utils/fileToBase64";
const origin = process.env.VUE_APP_BASE_URL;
import i18n from "../i18n";
interface submitFeedbackType {
  category: "problem" | "suggestion" | "cooperation";
  title?: string;
  content: string;
  contact_info: string;
  source?: 1 | 2;
  version?: string;
  system?: string;
  problem?: string;
  phone_model?: string;
  picture_list?: string[];
}

export const submitFeedback = (param: submitFeedbackType): Promise<void> => {
  return fetch(origin + "/ontoservice/feedback", {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.Desc === "SUCCESS") {
        Toast.success(i18n.global.t("tips_for_successful_training"));
        router.go(-1);
      }
    });
};

export const uploadFile = async (file: File): Promise<string> => {
  const fileBase64 = await fileToBase64(file);
  const nonce = _.random(20000, 99999999);

  const data = await fetch(origin + "/ontoservice/timestamp?t=" + nonce, {
    method: "get",
  });

  const timestamp = await data.json();
  console.log("timestamp", timestamp);

  const APP_ID = "ED4c103f";
  const APP_SECRET = "6e5bc5264b1045f79b00868cec7d90c2";
  const REQUEST_METHOD = "POST";
  const REQUEST_URL = "/S5/v2/ontoservice/s3/h5/upload_file";
  const payload = {
    picture_list: [fileBase64],
  };
  const md5Hash = Md5.hashStr(JSON.stringify(payload));
  // Base 64 string representation of the request payload
  const base64 = Buffer.from(md5Hash, "hex").toString("base64");
  // source data for sign
  const sourceData = `${APP_ID}${REQUEST_METHOD}${REQUEST_URL}${timestamp}${nonce}${base64}`;
  const signature = Base64.stringify(hmacSHA256(sourceData, APP_SECRET));
  const auth = `ont:${APP_ID}:${signature}:${nonce}:${timestamp}`;

  const uploadRes = await fetch(origin + "/ontoservice/s3/h5/upload_file", {
    method: "POST",
    headers: {
      Authorization: auth,
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(payload),
  });
  // console.log("uploadRes", uploadRes.json());
  const uploadResJson = await uploadRes.json();
  if (uploadResJson.Desc === "SUCCESS") {
    return uploadResJson.Result[0];
  } else {
    throw new Error();
  }
};
