
import { ref, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const isShow = ref<boolean>(false);
    const content = ref<string>("");
    const type = ref<"success">("success");
    const delay = ref<number>(3000);
    let timer = 0;

    const close = () => {
      window.clearTimeout(timer);
      isShow.value = false;
    };

    const open = () => {
      isShow.value = true;
      timer = window.setTimeout(close, delay.value);
    };

    return {
      content,
      type,
      delay,
      open,
      isShow,
    };
  },
});
