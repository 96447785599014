/**
 * @name
 * @author yuanzeyu
 * @date 2021/6/3
 * @desc
 */
import { createApp, ComponentPublicInstance } from "vue";
import ToastView from "./Toast.vue";

let instance: ComponentPublicInstance<Options & Methods> | null = null;

interface Options {
  content: string;
  delay?: number;
  type?: "success";
}

interface Methods {
  open: () => void;
}

enum Types {
  success = "success",
}

const defaultOptions: Options = {
  content: "",
  delay: 5000,
  type: Types.success,
};

type ToastType = ((options: Options | string) => void) & {
  success: (content: string) => void;
};

const Toast: ToastType = (options) => {
  if (!instance) {
    const app = createApp(ToastView);
    const container = document.createElement("div");
    instance = app.mount(container) as ComponentPublicInstance<
      Options & Methods
    >;
    document.body.appendChild(container);
  }
  const polyfillOptions: Options =
    typeof options === "string" ? { content: options } : options;
  instance.content = polyfillOptions.content || defaultOptions.content;
  instance.delay = polyfillOptions.delay || defaultOptions.delay;
  instance.type = polyfillOptions.type || defaultOptions.type;
  return instance.open();
};

Toast.success = (content: string) => Toast({ content, type: "success" });

export default Toast;
