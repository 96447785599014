import i18n from '../i18n'
const reg = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;

export const emailValidator = (
  rule: never,
  value: string,
  callback: (e?: Error) => void
): void => {
  if (!value.length) {
    callback(new Error(i18n.global.t("please_enter_email")));
  } else if (!reg.test(value)) {
    callback(new Error(i18n.global.t("email_format_error")));
  } else {
    callback();
  }
};
