<template>
  <div v-if="isShow" class="toast">
    <img
      v-if="type === 'success'"
      class="toast__icon"
      src="../../assets/img/success.svg"
      alt=""
    />
    <div class="toast__content">{{ content }}</div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const isShow = ref<boolean>(false);
    const content = ref<string>("");
    const type = ref<"success">("success");
    const delay = ref<number>(3000);
    let timer = 0;

    const close = () => {
      window.clearTimeout(timer);
      isShow.value = false;
    };

    const open = () => {
      isShow.value = true;
      timer = window.setTimeout(close, delay.value);
    };

    return {
      content,
      type,
      delay,
      open,
      isShow,
    };
  },
});
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  line-height: 52px;
  background: #ffffff;
}

.toast__icon {
  margin-right: 10px;
  height: 16px;
}

.toast__label {
  font-size: 14px;
  line-height: 28px;
  color: #000000;
}
@media screen and (max-width: 750px) {
  .toast {
    height: unset;
    line-height: 22px;
    padding: 0 20px;
  }
}
</style>
